import { createReframeCoordinates, createTranscriptionAction } from "../redux/file-browser/actions";
import { setConnected, setReConnecting } from "../redux/connection/connection-actions";
import { transcriptionProgress } from "../redux/transcription/transcription-actions";
import { clippingProgress } from "../redux/clipping/clipping-actions";
import { config } from '../config';
import { store } from "../redux/store";
import { message } from 'antd'
import { trimmingProgress } from "../redux/trimming/trimming-actions";
import { reframingProgress } from "../redux/reframing/reframing-actions";
import { downloadProgress } from "../redux/yt-download/download-action";

let requestCompletionStatus = {} as any;

export const connectSocket = async (clientId: string, _username: string, _JWTtoken: string) => {
    let socket: null | WebSocket = null;
    let pingPongInterval: null | NodeJS.Timeout = null;
    let totaltries = 10;

    const pingPong = () => {
        if (!socket || socket.readyState !== 1) {
            connect();
            return;
        }
        socket.send("heartbeat");
    }
    const socketRetry = () => {
        totaltries--;
        if (totaltries > 0) {
            connect();
            store.dispatch(setReConnecting());
        }
    };
    const connect = () => {
        if (pingPongInterval) {
            clearInterval(pingPongInterval);
        }

        //Set the websocket server URL
        const wsUrl = `${config.SOCKET_URL}${clientId}`;

        socket = new WebSocket(wsUrl);
        pingPongInterval = setInterval(pingPong, 15000);

        socket.onopen = () => {
            store.dispatch(setConnected({ value: true }));
        };
        socket.onclose = socketRetry
        socket.onerror = socketRetry

        socket.onmessage = (messageRecieved) => {
            try {
                const msg = messageRecieved.data.toString('utf8');
                if(msg === 'ping') {
                    socket.send("pong");
                } else {
                    const JSONmessage = JSON.parse(msg)
                    if (JSONmessage.type === 'TRANSCRIPTION') {
                        requestCompletionStatus = {}
                        store.dispatch(transcriptionProgress(JSONmessage.payload));
                    }
                    if (JSONmessage.type === 'CLIPS') {
                        store.dispatch(clippingProgress(JSONmessage.payload));
                    }
                    if (JSONmessage.type === 'COORDINATES') {                        
                        store.dispatch(reframingProgress(JSONmessage.payload));

                        // if (JSONmessage.payload.type == 'ERROR') {
                        //     message.error('Auto reframing Failed.')
                        // } 
                    }
                    if (JSONmessage.type === 'AUTOREFRAMING') {
                        console.log("AUTOREFRAMING :", JSONmessage.payload)
                        store.dispatch(reframingProgress(JSONmessage.payload));

                        if (JSONmessage.payload.type == 'ERROR') {
                            message.error('Auto reframing Failed.')
                        } 
                    }

                    if (JSONmessage.type === 'TRIMMING') {
                        const state = store.getState();
                        const time_stamps = state.trimmingReducer[JSONmessage.payload.projectId] || {};


                        const coordinatesPayload = {
                            url: JSONmessage.payload.url,
                            realtime_progress_webhook: config.REALTIME_PROGRESS_WEBHOOK,
                            user_id: JSONmessage.payload.userId,
                            project_id: JSONmessage.payload.projectId,
                        };
                        const transcriptionPayload = {
                            _id: JSONmessage.payload.projectId,
                            url: JSONmessage.payload.url,
                            user_id: JSONmessage.payload.userId,
                            lang: JSONmessage.payload.lang,
                            start_time: time_stamps.start_time,
                            end_time: time_stamps.end_time,
                        };
                        const trimmingProgressPayload = {
                            projectId: JSONmessage.payload.projectId,
                            trimming_status: 'Success',
                        };

                       if(!requestCompletionStatus[JSONmessage.payload.projectId]){
                        requestCompletionStatus[JSONmessage.payload.projectId] = true
                        console.log("FIRST REQUEST")
                        store.dispatch(trimmingProgress(trimmingProgressPayload));
                        store.dispatch(createTranscriptionAction(transcriptionPayload)).then(async () => {
                            store.dispatch(createReframeCoordinates(coordinatesPayload))
                        });
                       } else {
                        console.log("DUPLICATE REQUEST")
                       }
                    }
                    if (JSONmessage.type === 'YT_DOWNLOAD') {
                      if(JSONmessage.payload.status == 'SUCCESS'){
                        store.dispatch(downloadProgress(JSONmessage.payload));
                      } else if (JSONmessage.payload.status == 'ERROR') {
                        store.dispatch(downloadProgress(JSONmessage.payload));
                      } else if (JSONmessage.payload.status == 'PROGRESS') {
                        store.dispatch(downloadProgress(JSONmessage.payload));
                      }
                    }
                }
            } catch {
                message.error('Error when receiving data from the server')
            }
        }
    }
    if (!window.WebSocket) {
        alert("Your browser does not support the WebSocket API!");
    } else {
        connect();
    }
}
