import {
  GET_SHARE_PROFILE,
  LIST_FACEBOOK_PAGES,
  LIST_INSTAGRAM_ACCOUNTS,
  LIST_LINKEDIN_CREATOR_INFO,
  LIST_TIKTOK_CREATOR_INFO,
  LIST_YOUTUBE_USER_INFO,
  REVOKE_SHARE_ACCESS,
  LIST_TWITTER_USER_INFO,
} from './types';

export const getProfileAction = (payload: any) => (dispatch: any) => {
  return new Promise<any>((resolve, reject) => {
    dispatch({ type: GET_SHARE_PROFILE, payload, resolve, reject });
  });
};

export const listFacebookPagesAction = (payload: any) => (dispatch: any) => {
  return new Promise<any>((resolve, reject) => {
    dispatch({ type: LIST_FACEBOOK_PAGES, payload, resolve, reject });
  });
};

export const listInstagramAccountsAction = (payload: any) => (dispatch: any) => {
  return new Promise<any>((resolve, reject) => {
    dispatch({ type: LIST_INSTAGRAM_ACCOUNTS, payload, resolve, reject });
  });
};

export const listTiktokCreatorInfoAction = (payload: any) => (dispatch: any) => {
  return new Promise<any>((resolve, reject) => {
    dispatch({ type: LIST_TIKTOK_CREATOR_INFO, payload, resolve, reject });
  });
};

export const listYoutubeUserInfoAction = (payload: any) => (dispatch: any) => {
  return new Promise<any>((resolve, reject) => {
    dispatch({ type: LIST_YOUTUBE_USER_INFO, payload, resolve, reject });
  });
};

export const listLinkedinCreatorInfoAction = (payload: any) => (dispatch: any) => {
  return new Promise<any>((resolve, reject) => {
    dispatch({ type: LIST_LINKEDIN_CREATOR_INFO, payload, resolve, reject });
  });
};

export const listTwitterUserInfoAction = (payload: any) => (dispatch: any) => {
  return new Promise<any>((resolve, reject) => {
    dispatch({ type: LIST_TWITTER_USER_INFO, payload, resolve, reject });
  });
};

export const revokeShareAccessAction = (payload: any) => (dispatch: any) => {
  return new Promise<any>((resolve, reject) => {
    dispatch({ type: REVOKE_SHARE_ACCESS, payload, resolve, reject });
  });
};
