import {
  BgColorsOutlined, LeftOutlined,
  RightOutlined, ShareAltOutlined, DownOutlined
} from '@ant-design/icons';
import { Layout, Menu } from "antd";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect, Route, useHistory, useLocation } from 'react-router-dom';
import { ReactComponent as DashboardIcon } from '../assets/icons/dashboard_icon.svg';
import AppLoader from '../component/Apploader';
import Header from '../component/header';
import { default as config } from '../config';
import { ResetAction } from '../redux/branding/actions';
import { createWorkspaceTemplate, getAllDirAction, getAllProAction, resetFolderChain, updateCurrentDirectoryAction, updateFolderChain } from '../redux/file-browser/actions';
import * as types from '../redux/file-browser/types';
import { getProfileAction } from '../redux/share/actions';
import { RootState } from '../redux/store';
import { PAGINATION_LIMIT } from '../utils/constants';
import { checkSubdomain } from '../utils/utils';
import styles from './style.module.scss';

interface IProps {
  component: any;
  path: string;
  exact: boolean;
}

declare const window: any;
const { Sider, Content } = Layout;
const PrivateRoute = ({ component: Component, ...rest }: IProps) => {

  const isAuthenticated = useSelector((state: RootState) => state.authReducer.isAuthenticated);
  const currentUser = useSelector((state: RootState) => state.authReducer.profile);
  const currentWorkspace = useSelector((state: RootState) => state.browserReducer.currentWorkspace);
  const allWorkSpaces = useSelector((state: RootState) => state.browserReducer.allWorkSpaces);
  const fbLoading = useSelector((state: RootState) => state.browserReducer.fbLoading);
  const folderChain = useSelector((state: RootState) => state.browserReducer.folderChain);

  const [collapsed, setCollapsed] = useState(true);
  const [currentPage, setCurrentPage] = useState("sub1");
  const [showWorkspaceDropdown, setShowWorkspaceDropdown] = useState(false);

  const history = useHistory()
  const location = useLocation();
  const dispatch = useDispatch();

  const toggle = () => { setCollapsed(!collapsed); setShowWorkspaceDropdown(false)};

  const { SubMenu } = Menu;

  useEffect(() => {
    const currentWorkspace = localStorage.getItem('currentWorkspace');
    if (currentWorkspace) {
      updateCurrentWorkspace(JSON.parse(currentWorkspace));
    }
  }, []);


  useEffect(() => {
    if (currentWorkspace && !currentWorkspace.templatesGroupId) {
      dispatch(createWorkspaceTemplate({
        "userId": currentWorkspace.userId,
        "workspaceId": currentWorkspace._id,
        "ratio": "vertical",
        "type": "account",
        "subtitleStyle": {
          "fontFamily": "KomikaAxis-Bold",
          "fontSize": "42",
          "primaryColor": "#ff0000",
          "primaryStrokeColor": "#ffffff",
          "secondaryColor": "#ffffff",
          "secondaryStrokeColor": "#000000",
          "textShadowColor": "#00ff2a",
          "vAlign": "end",
          "linesPerFrame": 1,
          "lineHeight": 42,
          "uppercase": false
        },
        "layout": 'fill',
        "elements": []
      }));
    }
  }, [currentWorkspace])

  useEffect(() => {
    if (location.pathname.trim() === '/') {
      setCurrentPage('sub1')
    }
    if (location.pathname.trim() === '/connections') {
      setCurrentPage('sub2')
    }
    if (location.pathname.trim() === '/branding') {
      setCurrentPage('sub3')
    }

  }, [location])


  const handleDashboardClicked = () => {
    if (!collapsed) {
      setShowWorkspaceDropdown(!showWorkspaceDropdown);
    }
    dispatch({ type: types.SET_TAB_TITLE, payload: 'Home' });
    if (location.pathname.trim() !== '/') {
      setCurrentPage('sub1');
      dispatch(ResetAction(true));
      if (!folderChain || folderChain[0] === undefined) {
        dispatch(updateFolderChain(currentWorkspace));
      } else {
        const desiredItem = folderChain?.find(folder => folder?.name == currentWorkspace?.name);
        dispatch(updateFolderChain(desiredItem));
      }
      history.push('/');
    }
  }


  const updateCurrentWorkspace = async (item: any) => {
    // Set cookie for the main domain (keevi.io)
    document.cookie = `currentWorkspace=${JSON.stringify(item)}; domain=.keevi.io; path=/`;
    
    // Keep localStorage for backward compatibility
    localStorage.setItem('currentWorkspace', JSON.stringify(item));
    
    setShowWorkspaceDropdown(false);
    if (checkSubdomain(item.workspaceDomain ? item?.workspaceDomain : null) === false) {
      const weblink = `https://${item?.workspaceDomain ? item?.workspaceDomain + '.' : ''}${config.dashboardLink}`;
      window.location.href = weblink
    }

    dispatch(getProfileAction({ userId: currentWorkspace?.userId, activeEmail: currentUser?.email }))

    dispatch({
      type: types.UPDATE_WORKSPACE,
      payload: { ...item },
    });
    dispatch({
      type: types.SET_PROJECTS_TO_EMPTY,
      payload: []
    })
    dispatch(resetFolderChain([]));
    dispatch(updateFolderChain(item));
    dispatch(updateCurrentDirectoryAction(item));
    setTimeout(() => {
      dispatch(
        getAllDirAction({
          workspace: item
            ? item?._id
            : item,
          parentDirectory: null,
          userId: item?.userId,
        }),
      );
      dispatch(getAllProAction({
        workspace: item ? item?._id : item,
        parentDirectory: null,
        userId: item?.userId,
        pageNo: 1,
        size: PAGINATION_LIMIT,
      }))
    }, 200);

  }


  return (

    <Route
      {...rest}
      render={props => {
        if (isAuthenticated && currentWorkspace && currentWorkspace.onBoard === true) {
          return (
            <div className={`vumu-theme`}>
              {fbLoading && (
                <div className="pre-loader">
                  <AppLoader />
                </div>
              )}
              <div className="dashboard-body">
                <Layout className='dashboard_body_innerLayout'>
                  <Sider width={"285px"}
                    collapsedWidth={window.innerWidth > 767 ? 64 : 64} trigger={null} collapsible
                    collapsed={collapsed} onBreakpoint={(broken) => {
                    }}>
                    <div className="logo collapseControl" style={{ overflow: 'hidden' }}>
                      <img className="min-logo" src={currentWorkspace?.workspaceLogo ?? config.DESTINATION_MEDIA_CDN + "assets/logo.png"} style={{ maxWidth: '90%', width: '100%', objectFit: 'contain', margin: 'auto' }}></img>
                      <img className="max-logo" src={currentWorkspace?.workspaceLogo ?? config.DESTINATION_MEDIA_CDN + "assets/logo_full.png"} style={{ marginLeft: '12px', height: '30px' }}></img>
                      {React.createElement(collapsed ? RightOutlined : LeftOutlined, {
                        className: 'trigger collapseDot forWeb',
                        onClick: toggle,
                      })}
                      {React.createElement(collapsed ? RightOutlined : LeftOutlined, {
                        className: 'trigger collapseDot forMob',
                        onClick: toggle,
                      })}
                    </div>
                    <Menu className="firstMenuWrap" mode="inline" selectedKeys={[currentPage]} style={{ marginBottom: "35px" }}>
                      <SubMenu
                        className="firstMenu"
                        key="sub1"
                        icon={<DashboardIcon style={{ 'fontSize': '28px', color: '#8493a6' }} />}
                        title={
                          <div className="workSpaceMenuBtn">
                            <Link to="/" className="active dashboardLink" onClick={() => { handleDashboardClicked() }}></Link>
                            {currentWorkspace && (
                              <div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
                                <p>{currentWorkspace.name}</p>
                                <DownOutlined style={{ fontSize: '12px', color: '#8493a6' }} />
                              </div>
                            )}
                          </div>
                        }
                      >
                        {window.location.pathname == '/branding' ? "" : allWorkSpaces.map((item: any, idx: any) => (
                                <div 
                                  className={styles.workspaceItem}
                                  key={idx} 
                                  onClick={() => updateCurrentWorkspace(item)}
                                >
                                  <div className={styles.workspaceIcon}>
                                    {item.workspaceLogo 
                                      ? <img src={item.workspaceLogo} alt={item.name} />
                                      : <span>{item.name[0].toUpperCase()}</span>
                                    }
                                  </div>
                                  <div className={styles.workspaceInfo}>
                                    <span className={styles.workspaceName}>{item.name}</span>
                                  </div>
                                  <div className={styles.activeIndicator}>
                                    {currentWorkspace?._id === item._id && (
                                      <span className={styles.activeDot} />
                                    )}
                                  </div>
                                </div>
                              ))}
                      </SubMenu>
                      <SubMenu
                        className="firstMenu"
                        key="sub2"
                        icon={<ShareAltOutlined style={{ 'fontSize': '28px', color: '#8493a6' }} />}
                        title={
                          <div className="workSpaceMenuBtn">
                            <Link to="/connections" className="active dashboardLink" onClick={() => { setCurrentPage('sub2'); dispatch({ type: types.SET_TAB_TITLE, payload: 'Connections' }) }}></Link>
                            <p>Connections</p>
                          </div>
                        }
                      >
                      </SubMenu>
                      <SubMenu
                        className="firstMenu"
                        key="sub3"
                        icon={<BgColorsOutlined style={{ 'fontSize': '28px', color: '#8493a6' }} />}
                        title={
                          <div className="workSpaceMenuBtn">
                            <Link to="/branding" className="active dashboardLink" onClick={() => { setCurrentPage('sub3'); dispatch({ type: types.SET_TAB_TITLE, payload: 'Branding' }) }}></Link>
                            <p>Brand Settings</p>
                          </div>
                        }
                      >
                      </SubMenu>

                    </Menu>
                    {!collapsed && showWorkspaceDropdown && (
                      <div className={styles.mainContainer}>
                        <div className={styles.workspaceDropdown}>
                          {window.location.pathname == '/branding'
                            ? "" 
                            : allWorkSpaces.map((item: any, idx: any) => (
                                <div 
                                  className={styles.workspaceItem}
                                  key={idx} 
                                  onClick={() => updateCurrentWorkspace(item)}
                                >
                                  <div className={styles.workspaceIcon}>
                                    {item.workspaceLogo 
                                      ? <img src={item.workspaceLogo} alt={item.name} />
                                      : <span>{item.name[0].toUpperCase()}</span>
                                    }
                                  </div>
                                  <div className={styles.workspaceInfo}>
                                    <span className={styles.workspaceName}>{item.name}</span>
                                  </div>
                                  <div className={styles.activeIndicator}>
                                    {currentWorkspace?._id === item._id && (
                                      <span className={styles.activeDot} />
                                    )}
                                  </div>
                                </div>
                              ))}
                        </div>
                      </div>
                    )}
                  </Sider>
                  <Layout className="site-layout-background"
                    style={{ backgroundColor: '#fff', minHeight: '280px', }}>
                    <Header />
                    <Content
                      style={{
                        minHeight: 280,
                      }}
                    >
                      <div>
                        <Component {...props} />
                      </div>
                    </Content>
                  </Layout>
                </Layout>
              </div>
            </div>
          );
        }
        else if (isAuthenticated && (currentWorkspace?.onBoard === false)) {
          return (
            <div className={`vumu-theme`} style={{ width: '80vw', height: '80vh', margin: 'auto', marginTop: 50 }}>
              {fbLoading && (
                <div className="pre-loader">
                  <AppLoader />
                </div>
              )}
              <iframe style={{ width: '100%', height: '100%', border: 'none' }} src={`https://form.typeform.com/to/cvQZpdJw#user_id=${currentWorkspace.userId}`}></iframe>
            </div>
          )
        } else {
          return (
            <Redirect
              to={{
                pathname: '/',
                state: {
                  from: props.location,
                },
              }}
            />
          );
        }
      }}
    />
  );
};

export default PrivateRoute;
