import {
  SET_SHARE_PROFILE,
  SET_FACEBOOK_PAGES,
  SET_INSTAGRAM_ACCOUNTS,
  SET_TIKTOK_CREATOR_INFO,
  SET_YOUTUBE_USER_INFO,
  SET_LINKEDIN_CREATOR_INFO,
  SET_TWITTER_USER_INFO,
  SHARE_DATA_LOADED,
} from './types';
import { Reducer } from 'redux';
import { ActionType } from '../interface';

interface ShareProfile {
  phone: {
    countryCode: string | null;
    phoneNumber: string | null;
  };
  companyName: string | null;
  country: string | null;
  emailHistory: any | null;
  activeEmailSender: string | null;
  senderFromName: string | null;
  associatedGmailAccounts: string[] | null;
  associatedDomain: string | null;
  name: string;
  associatedSubomain: string | null;
  associatedPhonenumber: string;
  senderEmail: string | null;
  integrations: {
    twitter: { connected: boolean };
    tiktok: { connected: boolean };
    instagram: { connected: boolean };
    facebook: { connected: boolean };
    youtube: { connected: boolean };
    linkedin: { connected: boolean };
  };
  senderList: any | null;
  _id: string;
  userId: string;
  activeEmail: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
  consumed_videos_hours_per_month: number | null;
  consumption_states: {
    hours_consumed_per_month: number,
    reset_date: Date | null,
  };
  billing_plan: {
    billing_plan_id: string | null;
    payment_gateway_type: string | null;
    payment_gateway_plan_id: string | null;
    payment_gateway_event_id: string | null;
    invoice_item_uuid: string | null;
    videos_hours_per_month: number | null;
    is_life_time: boolean;
    is_active: boolean;
    plan_expire_on: Date | null;
    created_on: Date;
    updated_on: Date;
  };
}

interface InitialStateProps {
  shareProfile: ShareProfile | null;
  facebookPages: any | null;  
  instagramAccounts: any | null;
  tiktokCreatorInfo: any | null;
  youtubeUserInfo: any | null;
  linkedinCreatorInfo: any | null;
  twitterUserInfo: any | null;
  shareDataLoaded: boolean;
}

const initState: InitialStateProps = {
  shareProfile: {
    phone: {
      countryCode: '',
      phoneNumber: '',
    },
    companyName: '',
    country: '',
    emailHistory: [],
    activeEmailSender: '',
    senderFromName: '',
    associatedGmailAccounts: [],
    associatedDomain: '',
    name: '',
    associatedSubomain: '',
    associatedPhonenumber: '',
    senderEmail: '',
    integrations: {
      twitter: { connected: false },
      tiktok: { connected: false },
      instagram: { connected: false },
      facebook: { connected: false },
      youtube: { connected: false },
      linkedin: { connected: false },
    },
    senderList: [],
    _id: '',
    userId: '',
    activeEmail: '',
    createdAt: '',
    updatedAt: '',
    __v: 0,
    consumed_videos_hours_per_month: 0,
    consumption_states: {
      hours_consumed_per_month: 0,
      reset_date: null,
    },
    billing_plan: {
      billing_plan_id: null,
      payment_gateway_type: null,
      payment_gateway_plan_id: null,
      payment_gateway_event_id: null,
      invoice_item_uuid: null,
      videos_hours_per_month: null,
      is_life_time: false,
      is_active: true,
      plan_expire_on: null,
      created_on: new Date(),
      updated_on: new Date(),
    },
  },
  facebookPages: null,
  instagramAccounts: null,
  tiktokCreatorInfo: null,
  youtubeUserInfo: null,
  linkedinCreatorInfo: null,
  twitterUserInfo: null,
  shareDataLoaded: false,
};

const shareReducer: Reducer<InitialStateProps, ActionType> = (state = initState, action) => {
  switch (action.type) {
    case SET_SHARE_PROFILE:
      return { ...state, shareProfile: action.payload };
    case SET_FACEBOOK_PAGES:
      return { ...state, facebookPages: action.payload };
    case SET_INSTAGRAM_ACCOUNTS:
      return { ...state, instagramAccounts: action.payload };
    case SET_TIKTOK_CREATOR_INFO:
      return { ...state, tiktokCreatorInfo: action.payload };
    case SET_YOUTUBE_USER_INFO:
      return { ...state, youtubeUserInfo: action.payload };
    case SET_LINKEDIN_CREATOR_INFO:
      return { ...state, linkedinCreatorInfo: action.payload };
    case SET_TWITTER_USER_INFO:
      return { ...state, twitterUserInfo: action.payload };
    case SHARE_DATA_LOADED:
      return { ...state, shareDataLoaded: action.payload };
    default:
      return state;
  }
};

export default shareReducer;
