import apiUrl from '../config';
import { AxiosRequestConfig } from 'axios';
import { getUToken } from './auth.helper';
import { message } from 'antd';
import {axiosApi, axiosApiGateway, axiosStudioApi, axiosUst, axiosVumuEmbed,axiosApiYtGateway} from './interceptor';
import jwtDecode from 'jwt-decode';
import { getSession } from './ust.helper';
import { UserModel } from '../model';
import { store } from '../redux/store';
import { SET_PROFILE } from '../redux/auth/types';

interface IProps {
  url: AxiosRequestConfig['url'];
  payload: AxiosRequestConfig['data'];
  method: AxiosRequestConfig['method'];
  token?: string;
}

export const callApi = async ({ url, payload, method }: IProps) => {
  const token = getUToken();
  return token ?
    await callApiWithToken({ url, payload, method, token }) :
    await callApiWithoutToken({ url, payload, method })
}

export const callApiGateway = async ({ url, payload, method }: IProps) => {
  const token = getUToken();
  return token ?
    await callApiGatewayWithToken({ url, payload, method, token }) :
    await callApiGatewayWithoutToken({ url, payload, method })
}
export const callApiYtGateway = async ({ url, payload, method }: IProps) => {
  const token = getUToken();
  return token ?
    await callApiYtGatewayWithToken({ url, payload, method, token }) :
    await callApiGatewayWithoutToken({ url, payload, method })
}
export const callApiTokenPayload = async ({ url, payload, method }: IProps) => {
    const token = payload.token
    delete payload.token
    return await callApiGatewayWithToken({ url, payload, method, token})
}

export const callStudioApi = async ({ url, payload, method }: IProps) => {
  const token = getUToken();
  return token ?
    await callStudioApiWithToken({ url, payload, method, token }) :
    await callStudioApiWithoutToken({ url, payload, method })
}
export const callVumuEmbedApi = async ({ url, payload, method }: IProps) => {
  const token = getUToken();
  return token ?
      await callVumuEmbedApiWithToken({ url, payload, method, token }) :
      await callVumuEmbedApiWithoutToken({ url, payload, method })
}

export const callUstApi = async ({ url, payload, method }: IProps) => {
    const token = getUToken();
    return token ?
        await callUstApiWithToken({ url, payload, method, token }) :
        await callUstApiWithoutToken({ url, payload, method })
}

export const callApiWithToken = async ({ url, payload, method, token }: IProps) => {
  return method === 'GET' ?
    await axiosApi({
      url: url,
      method: method,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
    }).then((res: any) => {
      return res.data;
    }).catch((err: any) => {
      message.error("something went wrong");
    }) : await axiosApi({
      url: url,
      method: method,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      data: payload
    }).then((res: any) => {
      return res;
    }).catch((err: any) => {
      message.error("something went wrong");
    });
}

export const callApiWithoutToken = async ({ url, payload, method }: IProps) => {
  return method === 'GET' ?
    await axiosApi({
      url: url,
      method: method,
      headers: {
        'Content-Type': 'application/json'
      },
    }).then((res: any) => {
      return res.data;
    }).catch((err: any) => {
      message.error("something went wrong");
    }) : await axiosApi({
      url: url,
      method: method,
      headers: {
        'Content-Type': 'application/json'
      },
      data: payload
    }).then((res: any) => {
      return res;
    }).catch((err: any) => {
      message.error("something went wrong");
    });
}

export const callApiGatewayWithToken = async ({ url, payload, method }: IProps) => {
  const token = getUToken();
  const _token = await checkAndRefreshToken(token)

  return method === 'GET' ?
    await axiosApiGateway({
      url: url,
      method: method,
      headers: {
        Authorization: `Bearer ${_token}`,
        'Content-Type': 'application/json'
      },
    }).then((res: any) => {
      return res.data ? res.data : res;
    }).catch((err: any) => {
      message.error("something went wrong");
    }) : await axiosApiGateway({
      url: url,
      method: method,
      headers: {
        'Authorization': 'Bearer ' + _token,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      data: payload
    }).then((res: any) => {
      // console.log({ res });
      return res;
    }).catch((err: any) => {
      if(err.response.status === 429){
        return {message: err.response.data.message, status: err.response.status}
      }
      return null;
    });
}

export const callApiGatewayWithoutToken = async ({ url, payload, method }: IProps) => {
  return method === 'GET' ?
    await axiosApiGateway({
      url: url,
      method: method,
      headers: {
        'Content-Type': 'application/json'
      },
    }).then((res: any) => {
      return res.data;
    }).catch((err: any) => {
      message.error("something went wrong");
    }) : await axiosApiGateway({
      url: url,
      method: method,
      headers: {
        'Content-Type': 'application/json'
      },
      data: payload
    }).then((res: any) => {
      return res;
    }).catch((err: any) => {
      message.error("something went wrong");
    });
}

export const callStudioApiWithToken = async ({ url, payload, method, token }: IProps) => {
  return method === 'GET' ?
    await axiosStudioApi({
      url: url,
      method: method,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
    }).then((res: any) => {
      return res.data;
    }).catch((err: any) => {
      message.error("something went wrong");
    }) : await axiosStudioApi({
      url: url,
      method: method,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      data: payload
    }).then((res: any) => {
      return res;
    }).catch((err: any) => {
      message.error("something went wrong");
    });
}

export const callStudioApiWithoutToken = async ({ url, payload, method }: IProps) => {
  return method === 'GET' ?
    await axiosStudioApi({
      url: url,
      method: method,
      headers: {
        'Content-Type': 'application/json'
      },
    }).then((res: any) => {
      return res.data;
    }).catch((err: any) => {
      message.error("something went wrong");
    }) : await axiosStudioApi({
      url: url,
      method: method,
      headers: {
        'Content-Type': 'application/json'
      },
      data: payload
    }).then((res: any) => {
      return res;
    }).catch((err: any) => {
      message.error("something went wrong");
    });
}

export const callVumuEmbedApiWithToken = async ({ url, payload, method, token }: IProps) => {
  return method === 'GET' ?
      await axiosVumuEmbed({
        url: url,
        method: method,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      }).then((res: any) => {
        return res.data;
      }).catch((err: any) => {
        message.error("something went wrong");
      }) : await axiosVumuEmbed({
        url: url,
        method: method,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        data: payload
      }).then((res: any) => {
        return res;
      }).catch((err: any) => {
        message.error("something went wrong");
      });
}

export const callVumuEmbedApiWithoutToken = async ({ url, payload, method }: IProps) => {
  return method === 'GET' ?
      await axiosVumuEmbed({
        url: url,
        method: method,
        headers: {
          'Content-Type': 'application/json'
        },
      }).then((res: any) => {
        return res.data;
      }).catch((err: any) => {
        message.error("something went wrong");
      }) : await axiosVumuEmbed({
        url: url,
        method: method,
        headers: {
          'Content-Type': 'application/json'
        },
        data: payload
      }).then((res: any) => {
        return res;
      }).catch((err: any) => {
        message.error("something went wrong");
      });
}


export const callUstApiWithToken = async ({ url, payload, method, token }: IProps) => {
    return method === 'GET' ?
        await axiosUst({
            url: url,
            method: method,
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((res: any) => {
            return res;
        }).catch((err: any) => {
            message.error("something went wrong");
        }) : await axiosUst({
            url: url,
            method: method,
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: payload
        }).then((res: any) => {
            return res.status === 200 ? {userDetails: res.data.data, message: "Successfully fetched user Details"} : null;
        }).catch((err: any) => {
            console.log(err)
            return err ? {userDetails: null,  message: err.response.data.message} : null

            // message.error("something went wrong");
        });
}

export const callUstApiWithoutToken = async ({ url, payload, method }: IProps) => {
    return method === 'GET' ?
        await axiosUst({
            url: url,
            method: method,
            headers: {
                'Content-Type': 'application/json'
            },
        }).then((res: any) => {
            return res;
        }).catch((err: any) => {
            message.error("something went wrong");
        }) : await axiosUst({
            url: url,
            method: method,
            headers: {
                'Content-Type': 'application/json'
            },
            data: payload
        }).then((res: any) => {
            return res.data;
        }).catch((err: any) => {
            message.error("something went wrong");
        });
}
export const callApiYtGatewayWithToken = async ({ url, payload, method, token }: IProps) => {
  const _token = await checkAndRefreshToken(token)
  return method === 'GET' ?
    await axiosApiYtGateway({
      url: url,
      method: method,
      headers: {
        Authorization: `Bearer ${_token}`,
        'Content-Type': 'application/json'
      },
    }).then((res: any) => {
      return res.data ? res.data : res;
    }).catch((err: any) => {
      message.error("something went wrong");
    }) : await axiosApiYtGateway({
      url: url,
      method: method,
      headers: {
        'Authorization': 'Bearer ' + _token,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      data: payload
    }).then((res: any) => {
      // console.log({ res });
      return res;
    }).catch((error: any) => {
      return error;
    });
}


export const checkAndRefreshToken = async (token: any) => {
	
  try {
		const decoded = jwtDecode(token) as any;
		const expirationDate = new Date(decoded.exp * 1000);
    // @ts-ignore
		if (Date.now() > expirationDate) {
			const session = await getSession();
			const parsedData = new UserModel(session, session.idToken.jwtToken);
			store.dispatch({ type: SET_PROFILE, payload: parsedData });
      return parsedData.jwtToken;
		} else {
			return token;
		}
	} catch (error) {
		console.error('Error decoding token:', error);
		return null;
	}
};