export const GET_SHARE_PROFILE = 'GET_SHARE_PROFILE';
export const SET_SHARE_PROFILE = 'SET_SHARE_PROFILE';
export const LIST_FACEBOOK_PAGES = 'LIST_FACEBOOK_PAGES';
export const SET_FACEBOOK_PAGES = 'SET_FACEBOOK_PAGES';
export const LIST_INSTAGRAM_ACCOUNTS = 'LIST_INSTAGRAM_ACCOUNTS';
export const SET_INSTAGRAM_ACCOUNTS = 'SET_INSTAGRAM_ACCOUNTS';
export const LIST_TIKTOK_CREATOR_INFO = 'LIST_TIKTOK_CREATOR_INFO';
export const SET_TIKTOK_CREATOR_INFO = 'SET_TIKTOK_CREATOR_INFO';
export const LIST_YOUTUBE_USER_INFO = 'LIST_YOUTUBE_USER_INFO';
export const SET_YOUTUBE_USER_INFO = 'SET_YOUTUBE_USER_INFO';
export const LIST_LINKEDIN_CREATOR_INFO = 'LIST_LINKEDIN_CREATOR_INFO';
export const SET_LINKEDIN_CREATOR_INFO = 'SET_LINKEDIN_CREATOR_INFO';
export const LIST_TWITTER_USER_INFO = 'LIST_TWITTER_USER_INFO';
export const SET_TWITTER_USER_INFO = 'SET_TWITTER_USER_INFO';
export const REVOKE_SHARE_ACCESS = 'REVOKE_SHARE_ACCESS';
export const SHARE_DATA_LOADED = 'SHARE_DATA_LOADED';



export const SHARE_CONSTANTS = {
    TWITTER_OATH: "share/twitter-oauth-verifier/",
	TWITTER_REQUEST_TOKEN: "share/twitter-request-token",
    TIKTOK_OAUTH: "/share/tiktok-oauth",
	META_OAUTH: "/share/meta-app-oauth",
	YOUTUBE_OAUTH: "/share/youtube-oauth",
	LINKEDIN_OAUTH: "/share/linkedin-oauth",
};
