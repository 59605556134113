import authReducer from '../auth/reducers';
import browserReducer from '../file-browser/reducers';
import appReducer from '../app/reducers';
import renderReducer from '../render/render-reducer';
import transcriptionReducer from '../transcription/transcription-reducer';
import clippingReducer from '../clipping/clipping-reducer';
import connectionReducer from '../connection/connection-reducer';
import brandingReducer from '../branding/reducers';
import awsReducer from '../aws/reducers';
import { combineReducers } from 'redux';
import trimmingReducer from '../trimming/trimming-reducer';
import reframingReducer from '../reframing/reframing-reducer';
import downloadReducer from '../yt-download/download-reducer';
import shareReducer from '../share/reducer';

export const reducers = combineReducers({
  authReducer,
  browserReducer,
  appReducer,
  connectionReducer,
  renderReducer,
  transcriptionReducer,
  clippingReducer,
  trimmingReducer,
  reframingReducer,
  brandingReducer,
  awsReducer,
  downloadReducer,
  shareReducer,
})
