import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import { message } from 'antd';

import { callApiGatewayWithToken, callApiWithToken } from '../../helper/api.helper';
import {
  GET_SHARE_PROFILE,
  LIST_FACEBOOK_PAGES,
  SET_SHARE_PROFILE,
  SET_FACEBOOK_PAGES,
  LIST_INSTAGRAM_ACCOUNTS,
  LIST_TIKTOK_CREATOR_INFO,
  LIST_YOUTUBE_USER_INFO,
  SET_INSTAGRAM_ACCOUNTS,
  SET_TIKTOK_CREATOR_INFO,
  SET_YOUTUBE_USER_INFO,
  LIST_LINKEDIN_CREATOR_INFO,
  SET_LINKEDIN_CREATOR_INFO,
  LIST_TWITTER_USER_INFO,
  SET_TWITTER_USER_INFO,
  REVOKE_SHARE_ACCESS,
} from './types';

export function* getProfileSage() {
  yield takeEvery(GET_SHARE_PROFILE, function* ({ payload, resolve, reject }: any): any {   
    try {
      const response = yield call(getProfileApiCall, payload);
     
      if (response.redirect && window.location.pathname !== '/account/pricing') {
        window.location.href = response.url
      } else if (response._id) {
        yield put({ type: SET_SHARE_PROFILE, payload: response });
        message.destroy();
        resolve(response);
        return response
      }

     
    } catch (error) {
      message.error('Error getting Profile');
      reject(error);
    }
  });
}

export function* listFacebookPagesSage() {
  yield takeEvery(LIST_FACEBOOK_PAGES, function* ({ payload, resolve, reject }: any): any {
    try {
      const response = yield call(listFacebookPagesApiCall, payload);
      yield put({ type: SET_FACEBOOK_PAGES, payload: response });
      message.destroy();
      resolve(response);
    } catch (error) {
      message.error('Error in listing FB pages');
      reject(error);
    }
  });
}

export function* listInstagramAccountsSage() {
  yield takeEvery(LIST_INSTAGRAM_ACCOUNTS, function* ({ payload, resolve, reject }: any): any {
    try {
      const response = yield call(listInstagramAccountsApiCall, payload);
      yield put({ type: SET_INSTAGRAM_ACCOUNTS, payload: response });
      message.destroy();
      resolve(response);
    } catch (error) {
      message.error('Error in lisiting IG accounts');
      reject(error);
    }
  });
}

export function* listTiktokCreatorInfoSage() {
  yield takeEvery(LIST_TIKTOK_CREATOR_INFO, function* ({ payload, resolve, reject }: any): any {
    try {
      const response = yield call(listTiktokCreatorInfoApiCall, payload);
      yield put({ type: SET_TIKTOK_CREATOR_INFO, payload: response });
      message.destroy();
      resolve(response);
    } catch (error) {
      message.error('Error in getting Tiktok user info');
      reject(error);
    }
  });
}

export function* listYoutubeUserInfoSage() {
  yield takeEvery(LIST_YOUTUBE_USER_INFO, function* ({ payload, resolve, reject }: any): any {
    try {
      const response = yield call(listYoutubeUserInfoApiCall, payload);
      yield put({ type: SET_YOUTUBE_USER_INFO, payload: response });
      message.destroy();
      resolve(response);
    } catch (error) {
      message.error('Error in getting YT user info');
      reject(error);
      }
  });
}

export function* listLinkedinCreatorInfoSage() {
  yield takeEvery(LIST_LINKEDIN_CREATOR_INFO, function* ({ payload, resolve, reject }: any): any {
    try {
      const response = yield call(listLinkedinCreatorInfoApiCall, payload);
      yield put({ type: SET_LINKEDIN_CREATOR_INFO, payload: response });
      message.destroy();
      resolve(response);
    } catch (error) {
      message.error('Error in getting Linkedin user info');
      reject(error);
    }
  });
}

export function* listTwitterUserInfoSage() {
  yield takeEvery(LIST_TWITTER_USER_INFO, function* ({ payload, resolve, reject }: any): any {
    try {
      const response = yield call(listTwitterUserInfoApiCall, payload);
      yield put({ type: SET_TWITTER_USER_INFO, payload: response });
      message.destroy();
      resolve(response);
    } catch (error) {
      message.error('Error in getting Twitter user info');
      reject(error);
    }
  });
} 

export function* revokeShareAccessSage() {
  yield takeEvery(REVOKE_SHARE_ACCESS, function* ({ payload, resolve, reject }: any): any {
    try {
      const response = yield call(revokeShareAccessApiCall, payload);
      message.destroy();
      resolve(response);
    } catch (error) {
      message.error('Error in revoking share access');
      reject(error);
    }
  });
}

async function getProfileApiCall(payload: any) {
  return await callApiGatewayWithToken({ url: '/profile/get-profile', payload, method: 'POST' });
}

async function listFacebookPagesApiCall(payload: any) {
  return await callApiGatewayWithToken({
    url: '/share/list-facebook-pages',
    payload,
    method: 'POST',
  });
}

async function listInstagramAccountsApiCall(payload: any) {
  return await callApiGatewayWithToken({
    url: '/share/list-instagram-accounts',
    payload,
    method: 'POST',
  });
}

async function listTiktokCreatorInfoApiCall(payload: any) {
  return await callApiGatewayWithToken({
    url: '/share/tiktok-creator_info',
    payload,
    method: 'POST',
  });
}

async function listYoutubeUserInfoApiCall(payload: any) {
  return await callApiGatewayWithToken({
    url: '/share/youtube-user-info',
    payload,
    method: 'POST',
  });
}

async function listLinkedinCreatorInfoApiCall(payload: any) {
  return await callApiGatewayWithToken({
    url: '/share/linkedin-creator_info',
    payload,
    method: 'POST',
  });
}

async function listTwitterUserInfoApiCall(payload: any) {
  return await callApiGatewayWithToken({
    url: '/share/twitter-user-info',
    payload,
    method: 'POST',
  });
}

async function revokeShareAccessApiCall(payload: any) {
  return await callApiGatewayWithToken({
    url: '/share/revoke-share-access',
    payload,
    method: 'POST',
  });
}


export default function* shareSaga() {
  yield all([
    fork(getProfileSage),
    fork(listFacebookPagesSage),
    fork(listInstagramAccountsSage),
    fork(listTiktokCreatorInfoSage),
    fork(listYoutubeUserInfoSage),
    fork(listLinkedinCreatorInfoSage),
    fork(listTwitterUserInfoSage),
    fork(revokeShareAccessSage),
  ]);
}
